import React from 'react';
import PDFPreview from './PDFPreview';
import UserNotes from './UserNotes';

const ComplaintDetailsViewer = ({ complaint }) => {
  return (
    <div className="complaint-details-viewer">
      <PDFPreview complaint={complaint} />
      <UserNotes complaint={complaint} />
    </div>
  );
};

export default ComplaintDetailsViewer;
