// Dashboard.js
import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Modal from './Modal';
import Papa from 'papaparse';
import CSVUpload from './Upload'; 
import ViewModal from './ViewModal';
import ActionDropdown from './ActionDropdown';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import StoreTracking from './StoreTracking'; // New component for store tracking
import ComplaintTracking from './ComplaintTracking'; // New complaint component

const api_url = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  // State for tabs: 'meter' for Meter Tracking, 'store' for Store Tracking
  const [activeTab, setActiveTab] = useState('meter');

  // Pass the tab change handler to Header
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Existing states for meter tracking functionality
  const [searchTerm, setSearchTerm] = useState('');
  const [records, setRecords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [formData, setFormData] = useState({
    serial_number: '',
    meter_type: '',
    installation_date: '',
    site_name: '',
    delivery_status: '',
    status: '',
    old_sr_no: '',
    manufacture_date: '',
    warranty_status: '',
    delivery_date: '',
    received_from: '',
    in_qty: '',
    out_qty: '',
    out_from: '',
    remarks: '',
    complaint: '',
    gate_pass_no: '',
    product_name: '',
    chalan_no: ''
  });
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  
  const navigate = useNavigate();

  // --- Meter Tracking Functions (existing code) ---
  const handleView = (record) => {
    if (record && record.serial_number) {
      navigate(`/view-record/${record.serial_number}`, { state: { record } });
    } else {
      console.error('Record ID is missing!');
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
  };

  const handleDelete = (record) => {
    // Your delete logic here
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSearch = async () => {
    if (!searchTerm) {
      alert('Please enter a serial number');
      return;
    }
    try {
      const response = await fetch(`${api_url}/search_meter?serial_number=${searchTerm}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRecords(Array.isArray(data) && data.length > 0 ? data : []);
        if (!data.length) alert('No records found');
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleAddRecord = async () => {
    const { serial_number, meter_type, installation_date, site_name, delivery_status, status } = formData;
    if (!serial_number || !meter_type || !installation_date || !site_name || !delivery_status || !status) {
      alert('Please fill in all required fields');
      return;
    }
    try {
      const response = await fetch(`${api_url}/add_meter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        alert(data.message || 'Record added successfully!');
        setRecords((prevRecords) => [...prevRecords, formData]);
        clearForm();
        setIsModalOpen(false);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${api_url}/meters`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRecords(data);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${api_url}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        alert('Logged out successfully!');
        window.location.href = '/';
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('An error occurred during logout. Please try again.');
    }
  };

  const clearForm = () => {
    setFormData({
      serial_number: '',
      meter_type: '',
      installation_date: '',
      site_name: '',
      delivery_status: '',
      status: '',
      old_sr_no: '',
      manufacture_date: '',
      warranty_status: '',
      delivery_date: '',
      received_from: '',
      in_qty: '',
      out_qty: '',
      out_from: '',
      remarks: '',
      complaint: '',
      gate_pass_no: '',
      product_name: '',
      chalan_no: '',
      flat_no: '',
    });
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleClear = () => {
    setSearchTerm('');
    window.location.reload(); 
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setPreviewData(result.data.slice(0, 5));
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  const handleNext = () => {
    console.log("Saving data to DB:", uploadedFile);
    setIsCardOpen(false);
  };

  // Initial data fetch for meter records
  useEffect(() => {
    if (activeTab === 'meter') {
      fetchData();
    }
  }, [activeTab]);

  return (
    <div className="dashboard">
      {/* Pass activeTab and handleTabChange to Header */}
      <Header 
        onLogout={handleLogout}
        isLogoutDisabled={false}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />

      {/* Render content based on the active tab */}
      {activeTab === 'meter' && (
        <>
          <aside className="sidebar">
            <div className="card">
              <button className="button" onClick={() => setIsModalOpen(true)}>Add Record</button>
            </div>
            <div className="card form-section">
              <label className="form-label">Serial Number</label>
              <input 
                type="text" 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                placeholder="Enter serial number to search" 
                className="input" 
              />
              <button className="button" onClick={handleSearch}>Search</button>
              <button 
                className="button clear-button" 
                onClick={handleClear}
              >
                Clear Filter
              </button>
            </div>
          </aside>

          <main className="main-content">
            <h3 className="records-header">Meter Records</h3>
            <div className="add-button-section">
              <button className="button" onClick={() => setIsCardOpen(true)}>
                Import File
              </button>
            </div>

            {isCardOpen && (
              <CSVUpload 
                onFileChange={handleFileChange}
                onNext={handleNext}
                previewData={previewData}
                onClose={() => setIsCardOpen(false)}
              />
            )}

            {records.length > 0 ? (
              <table className="records-table">
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>Meter Type</th>
                    <th>Installation Date</th>
                    <th>Site Name</th>
                    <th>Delivery Status</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <tr key={index}>
                      <td>{record.serial_number}</td>
                      <td>{record.meter_type}</td>
                      <td>{record.installation_date}</td>
                      <td>{record.site_name}</td>
                      <td>{record.delivery_status}</td>
                      <td>{record.status}</td>
                      <td className="actions-cell">
                        <ActionDropdown
                          record={record}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                          onView={handleView}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="no-records">No records found</p>
            )}
          </main>

          {showViewModal && (
            <ViewModal
              record={selectedRecord}
              onClose={() => setShowViewModal(false)}
            />
          )}

          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <h2>Add Meter Record</h2>
            <div className="modal-content">
              <div className="form-section">
                <h3 onClick={() => toggleSection('basicInfo')} className={`section-header ${expandedSections.basicInfo ? 'open' : ''}`}>Basic Info</h3>
                {expandedSections.basicInfo && (
                  <>
                    <label className="form-label">Serial Number</label>
                    <input 
                      type="text" 
                      name="serial_number" 
                      value={formData.serial_number} 
                      onChange={handleInputChange} 
                      placeholder="Enter serial number" 
                      className="input" 
                    />
                    <label className="form-label">Meter Type</label>
                    <input 
                      type="text" 
                      name="meter_type" 
                      value={formData.meter_type} 
                      onChange={handleInputChange} 
                      placeholder="Enter meter type" 
                      className="input" 
                    />
                    <label className="form-label">Installation Date</label>
                    <input 
                      type="date" 
                      name="installation_date" 
                      value={formData.installation_date} 
                      onChange={handleInputChange} 
                      className="input" 
                    />
                    <label className="form-label">Site Name</label>
                    <input 
                      type="text" 
                      name="site_name" 
                      value={formData.site_name} 
                      onChange={handleInputChange} 
                      placeholder="Enter site name" 
                      className="input" 
                    />
                    <label className="form-label">Delivery Status</label>
                    <input 
                      type="text" 
                      name="delivery_status" 
                      value={formData.delivery_status} 
                      onChange={handleInputChange} 
                      placeholder="Enter delivery status" 
                      className="input" 
                    />
                    <label className="form-label">Status</label>
                    <input 
                      type="text" 
                      name="status" 
                      value={formData.status} 
                      onChange={handleInputChange} 
                      placeholder="Enter status" 
                      className="input" 
                    />
                  </>
                )}
              </div>
              <div className="form-section">
                <h3 onClick={() => toggleSection('additionalInfo')} className={`section-header ${expandedSections.additionalInfo ? 'open' : ''}`}>Additional Info</h3>
                {expandedSections.additionalInfo && (
                  <>
                    <label className="form-label">Old Serial Number</label>
                    <input 
                      type="text" 
                      name="old_sr_no" 
                      value={formData.old_sr_no} 
                      onChange={handleInputChange} 
                      placeholder="Enter old serial number" 
                      className="input" 
                    />
                    <label className="form-label">Manufacture Date</label>
                    <input 
                      type="date" 
                      name="manufacture_date" 
                      value={formData.manufacture_date} 
                      onChange={handleInputChange} 
                      className="input" 
                    />
                    <label className="form-label">Warranty Status</label>
                    <input 
                      type="text" 
                      name="warranty_status" 
                      value={formData.warranty_status} 
                      onChange={handleInputChange} 
                      placeholder="Enter warranty status" 
                      className="input" 
                    />
                    <label className="form-label">Delivery Date</label>
                    <input 
                      type="date" 
                      name="delivery_date" 
                      value={formData.delivery_date} 
                      onChange={handleInputChange} 
                      className="input" 
                    />
                    <label className="form-label">Received From</label>
                    <input 
                      type="text" 
                      name="received_from" 
                      value={formData.received_from} 
                      onChange={handleInputChange} 
                      placeholder="Enter received from" 
                      className="input" 
                    />
                  <label className="form-label">Flat No</label>
                  <input 
                    type="text" 
                    name="flat_no" 
                    value={formData.flat_no} 
                    onChange={handleInputChange} 
                    placeholder="Enter flat number" 
                    className="input" 
                  />
                  </>
                )}
              </div>
              <div className="form-section">
                <h3 onClick={() => toggleSection('inventoryInfo')} className={`section-header ${expandedSections.inventoryInfo ? 'open' : ''}`}>Inventory Info</h3>
                {expandedSections.inventoryInfo && (
                  <>
                    <label className="form-label">Incoming Quantity</label>
                    <input 
                      type="number" 
                      name="in_qty" 
                      value={formData.in_qty} 
                      onChange={handleInputChange} 
                      placeholder="Enter incoming quantity" 
                      className="input" 
                    />
                    <label className="form-label">Outgoing Quantity</label>
                    <input 
                      type="number" 
                      name="out_qty" 
                      value={formData.out_qty} 
                      onChange={handleInputChange} 
                      placeholder="Enter outgoing quantity" 
                      className="input" 
                    />
                    <label className="form-label">Outgoing From</label>
                    <input 
                      type="text" 
                      name="out_from" 
                      value={formData.out_from} 
                      onChange={handleInputChange} 
                      placeholder="Enter outgoing from" 
                      className="input" 
                    />
                    <label className="form-label">Remarks</label>
                    <input 
                      type="text" 
                      name="remarks" 
                      value={formData.remarks} 
                      onChange={handleInputChange} 
                      placeholder="Enter remarks" 
                      className="input" 
                    />
                    <label className="form-label">Complaint</label>
                    <input 
                      type="text" 
                      name="complaint" 
                      value={formData.complaint} 
                      onChange={handleInputChange} 
                      placeholder="Enter complaint" 
                      className="input" 
                    />
                  </>
                )}
              </div>
              <div className="form-section">
                <h3 onClick={() => toggleSection('gatePassInfo')} className={`section-header ${expandedSections.gatePassInfo ? 'open' : ''}`}>Gate Pass Info</h3>
                {expandedSections.gatePassInfo && (
                  <>
                    <label className="form-label">Gate Pass No</label>
                    <input 
                      type="text" 
                      name="gate_pass_no" 
                      value={formData.gate_pass_no} 
                      onChange={handleInputChange} 
                      placeholder="Enter gate pass number" 
                      className="input" 
                    />
                    <label className="form-label">Product Name</label>
                    <input 
                      type="text" 
                      name="product_name" 
                      value={formData.product_name} 
                      onChange={handleInputChange} 
                      placeholder="Enter product name" 
                      className="input" 
                    />
                    <label className="form-label">Chalan Number</label>
                    <input 
                      type="text" 
                      name="chalan_no" 
                      value={formData.chalan_no} 
                      onChange={handleInputChange} 
                      placeholder="Enter chalan number" 
                      className="input" 
                    />
                  </>
                )}
              </div>
            </div>
            <button className="button add-record" onClick={handleAddRecord}>Add Record</button>
          </Modal>
        </>
      )}

      {/* If the active tab is "store", show the StoreTracking component */}
      {activeTab === 'store' && <StoreTracking />}
      {activeTab === 'complaint' && <ComplaintTracking />}

    </div>
  );
};

export default Dashboard;
