import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';

// Register custom fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 'normal' },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: { padding: 40, backgroundColor: '#f9fafb', position: 'relative' },
  coverPage: { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff', padding: 20 },
  logo: { width: 120, height: 120, marginBottom: 20 },
  reportTitle: { fontSize: 24, fontWeight: 'bold', color: '#1a73e8', textAlign: 'center', marginBottom: 10 },
  companyDetails: { fontSize: 12, color: '#666', textAlign: 'center', marginTop: 10 },
  header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30, backgroundColor: '#ffffff', padding: 20 },
  companyName: { fontSize: 18, fontWeight: 'bold', color: '#1a73e8' },
  complaintHeading: { fontSize: 20, fontWeight: 'bold', color: '#1a73e8', marginBottom: 20, textAlign: 'center' },
  table: { width: '100%', backgroundColor: '#ffffff', padding: 20, marginBottom: 20 },
  tableRow: { flexDirection: 'row', borderBottom: '1px solid #eee', padding: '10px 0' },
  tableHeader: { flex: 1, fontWeight: 'bold', fontSize: 14, color: '#333' },
  tableCell: { flex: 1, fontSize: 14, color: '#444' },
  signatureSection: { marginTop: 30, paddingTop: 20, borderTop: '1px solid #eee', backgroundColor: '#ffffff', padding: 20 },
  label: { fontWeight: 'bold', fontSize: 14, color: '#333', marginBottom: 10 },
  signatureLine: { width: '200px', borderBottom: '1px solid #000', margin: '20px 0' },
  qrPage: { display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f9fafb', height: '100%' },
  qrPageContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' },
  qrLabel: { fontSize: 16, fontWeight: 'bold', color: '#1a73e8', marginBottom: 30, textAlign: 'center', width: '100%' },
  qrImage: { width: 300, height: 300 },
  footer: { position: 'absolute', bottom: 20, left: 0, right: 0, textAlign: 'center', fontSize: 10, color: '#666' },
  watermark: { position: 'absolute', opacity: 0.1, fontSize: 60, color: '#1a73e8', transform: 'rotate(-45deg)', top: '40%', left: '20%' },
});

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const PDFPreview = ({ complaint = {} }) => {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');

  useEffect(() => {
    const qrValue = `${window.location.origin}/share?data=${encodeURIComponent(`Complaint Details

Complaint No: ${complaint.complaint_no || 'N/A'}
Staff: ${complaint.staff_name || 'N/A'}
Site Name: ${complaint.site_name || 'N/A'}
Warranty Status: ${complaint.warranty_status || 'N/A'}
Complaint Date: ${formatDate(complaint.complaint_date)}
Nature of Complaint: ${complaint.nature_of_complaint || 'N/A'}
Description: ${complaint.description || 'N/A'}`)}`;

    if (qrValue && qrValue.trim() !== '') {
      QRCode.toDataURL(qrValue, { width: 200, height: 200, margin: 2 })
        .then(url => setQrCodeDataUrl(url))
        .catch(console.error);
    } else {
      console.error('No valid complaint data found');
    }
  }, [complaint]);

  if (!complaint || Object.keys(complaint).length === 0) {
    return <Text>No complaint data available</Text>;
  }

  const pdfContent = (
    <Document>
      <Page style={styles.coverPage}>
        <Image src='/logo.png' style={styles.logo} />
        <Text style={styles.reportTitle}>Complaint Report</Text>
        <Text style={styles.companyDetails}>SavEngineers.in</Text>
      </Page>

      <Page style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.companyName}>SavEngineers.in</Text>
            <Text style={styles.companyDetails}>
              A-71/2, First Floor, Hari Nagar Ashram, Mathura Road, New Delhi - 110014
            </Text>
          </View>
        </View>

        <Text style={styles.complaintHeading}>Complaint #{complaint.complaint_no || 'N/A'}</Text>

        <View style={styles.table}>
          {[
            { label: 'Complaint No:', value: complaint.complaint_no },
            { label: 'Staff:', value: complaint.staff_name },
            { label: 'Site Name:', value: complaint.site_name },
            { label: 'Warranty Status:', value: complaint.warranty_status },
            { label: 'Complaint Date:', value: formatDate(complaint.complaint_date) },
            { label: 'Nature of Complaint:', value: complaint.nature_of_complaint },
            { label: 'Description:', value: complaint.description },
          ].map((row, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableHeader}>{row.label}</Text>
              <Text style={styles.tableCell}>{row.value || 'N/A'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.signatureSection}>
          <Text style={styles.label}>Authorized Signature:</Text>
          <View style={styles.signatureLine} />
        </View>

        <View style={styles.footer}>
          <Text>Page 1 of 2 • Generated by Meter Tracking System</Text>
          <Text> SavEngineers.in. All rights reserved.</Text>
        </View>

        <Text style={styles.watermark}>CONFIDENTIAL</Text>
      </Page>

      <Page style={styles.qrPage}>
        <View style={styles.qrPageContainer}>
          <Text style={styles.qrLabel}>Scan to view complaint details</Text>
          {qrCodeDataUrl && <Image src={qrCodeDataUrl} style={styles.qrImage} />}
        </View>
        <View style={styles.footer}>
          <Text>Page 2 of 2 • Generated by Meter Tracking System</Text>
          <Text> SavEngineers.in. All rights reserved.</Text>
        </View>
      </Page>
    </Document>
  );

  const handleShareQRCode = async () => {
    try {
      const response = await fetch(qrCodeDataUrl);
      const blob = await response.blob();
      const file = new File([blob], `complaint_${complaint.complaint_no}_qr.png`, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: 'Complaint QR Code',
          text: 'Scan this QR to view complaint details',
          files: [file],
        });
      } else {
        alert('Sharing not supported on this device.');
      }
    } catch (error) {
      console.error('Error sharing QR code:', error);
    }
  };

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        {pdfContent}
      </PDFViewer>

      {qrCodeDataUrl && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
          <button
            onClick={() => {
              const a = document.createElement('a');
              a.href = qrCodeDataUrl;
              a.download = `complaint_${complaint.complaint_no}_qr.png`;
              a.click();
            }}
            style={{
              padding: '10px 20px',
              backgroundColor: '#1a73e8',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Download QR Code
          </button>

          <button
            onClick={handleShareQRCode}
            style={{
              padding: '10px 20px',
              backgroundColor: '#34d399',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Share QR Code
          </button>
        </div>
      )}
    </div>
  );
};

export default PDFPreview;
