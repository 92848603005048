import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SharePage = () => {
  const [data, setData] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const encodedData = queryParams.get('data');
    if (encodedData) {
      setData(decodeURIComponent(encodedData));
    }
  }, []);

  const parseData = (data) => {
    const lines = data.replace('data:text/plain;charset=utf-8,', '').split('\n').filter(line => line.trim() !== '');
    const parsedData = {};
    lines.forEach(line => {
      const [key, value] = line.split(':').map(part => part.trim());
      if (key && value) {
        parsedData[key] = value;
      }
    });
    return parsedData;
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(data)
      .then(() => toast.success('Data copied to clipboard!'))
      .catch(() => toast.error('Failed to copy data.'));
  };

  const parsedData = parseData(data);

  return (
    <div style={{ 
      padding: '2rem', 
      textAlign: 'center', 
      minHeight: '100vh', 
      backgroundColor: '#f8f9fa',
      fontFamily: '\'Inter\', -apple-system, BlinkMacSystemFont, sans-serif'
    }}>
      <h1 style={{ 
        fontSize: '2rem', 
        fontWeight: '600', 
        color: '#1a1a1a', 
        marginBottom: '2rem',
        letterSpacing: '-0.02em'
      }}>
        Complaint Details
      </h1>
      <div style={{ 
        background: '#fff', 
        padding: '2rem', 
        borderRadius: '12px', 
        maxWidth: '800px', 
        margin: '0 auto',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
        border: '1px solid rgba(0, 0, 0, 0.05)'
      }}>
        {Object.entries(parsedData).map(([key, value]) => (
          <div key={key} style={{ 
            marginBottom: '1rem', 
            textAlign: 'left',
            padding: '0.5rem',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            ...(key === 'description' && {
              maxHeight: '200px',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              paddingRight: '0.5rem'
            })
          }}>
            <strong style={{ color: '#1a1a1a', marginRight: '0.5rem', fontWeight: '500' }}>{key}:</strong> 
            <span style={{ color: '#4a4a4a' }}>{value}</span>
          </div>
        ))}
      </div>
      <button 
        onClick={handleCopy} 
        style={{ 
          marginTop: '2rem', 
          padding: '0.75rem 2rem', 
          fontSize: '1rem', 
          backgroundColor: '#1a1a1a', 
          color: '#fff', 
          border: 'none', 
          borderRadius: '8px', 
          cursor: 'pointer',
          transition: 'all 0.2s',
          ':hover': {
            backgroundColor: '#333',
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }
        }}
      >
        Copy Data
      </button>
      <ToastContainer />
    </div>
  );
};

export default SharePage;