import React from "react";
import { Modal, Form, Input, Select, Button, DatePicker } from "antd";

const { Option } = Select;

const AddProductForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="Add New Product"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onCreate(values);
          form.resetFields();
        }}
      >
        {/* Product Name */}
        <Form.Item
          name="name"
          label="Product Name"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>

        {/* Status Dropdown */}
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select product status" }]}
        >
          <Select placeholder="Select status">
            <Option value="Available">Available</Option>
            <Option value="Out of Stock">Out of Stock</Option>
            <Option value="Discontinued">Discontinued</Option>
          </Select>
        </Form.Item>

        {/* Bill No */}
        <Form.Item
          name="billNo"
          label="Bill No"
          rules={[{ required: true, message: "Please enter bill number" }]}
        >
          <Input placeholder="Enter bill number" />
        </Form.Item>

        {/* Challan No */}
        <Form.Item
          name="challanNo"
          label="Challan No"
          rules={[{ required: true, message: "Please enter challan number" }]}
        >
          <Input placeholder="Enter challan number" />
        </Form.Item>

        {/* Billable or Not */}
        <Form.Item
          name="billable"
          label="Billable"
          rules={[{ required: true, message: "Please select if billable" }]}
        >
          <Select placeholder="Select billable status">
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        </Form.Item>

        {/* Incoming Address */}
        <Form.Item
          name="incomingAddress"
          label="Incoming Address"
          rules={[{ required: true, message: "Please enter incoming address" }]}
        >
          <Input placeholder="Enter incoming address" />
        </Form.Item>

        {/* Arrival Date */}
        <Form.Item
          name="arrivalDate"
          label="Arrival Date"
          rules={[{ required: true, message: "Please select arrival date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        {/* Destination Address */}
        <Form.Item
          name="destinationAddress"
          label="Destination Address"
          rules={[{ required: true, message: "Please enter destination address" }]}
        >
          <Input placeholder="Enter destination address" />
        </Form.Item>

        {/* Departure Date */}
        <Form.Item
          name="departureDate"
          label="Departure Date"
          rules={[{ required: true, message: "Please select departure date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Product
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProductForm;
