import React, { useState } from 'react';
import { Button, Input } from 'antd';

const { TextArea } = Input;

const UserNotes = ({ complaint }) => {
  const [notes, setNotes] = useState(complaint.notes || '');
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/complaints/${complaint.complaint_no}/notes`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes }),
        credentials: 'include',
      });
      if (response.ok) {
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };

  return (
    <div className="user-notes">
      <h3>Notes</h3>
      {isEditing ? (
        <>
          <TextArea
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter your notes..."
          />
          <div className="notes-actions">
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>{notes || 'No notes available'}</p>
          <Button type="primary" onClick={() => setIsEditing(true)}>
            Add/Edit Notes
          </Button>
        </>
      )}
    </div>
  );
};

export default UserNotes;
