import React, { useState } from "react";
import { Button, Input, Table, Card, Space, Drawer, Badge, Select } from "antd";
import { PlusOutlined, SearchOutlined, EyeOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AddProductForm from "./AddProductForm";
import "./StoreTracking.css";

const { Option } = Select;

const StoreTracking = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const productRecords = [
    {
      id: "P001",
      name: "Product A",
      category: "Electronics",
      status: "Available",
      sales: 500,
      stock: 120,
      incomingAddress: "Los Angeles",
      arrivalDate: "2025-03-01",
      destinationAddress: "New York",
      departureDate: "2025-03-05",
    },
    {
      id: "P002",
      name: "Product B",
      category: "Clothing",
      status: "Out of Stock",
      sales: 300,
      stock: 0,
      incomingAddress: "Houston",
      arrivalDate: "2025-02-20",
      destinationAddress: "San Francisco",
      departureDate: "2025-02-25",
    },
    {
      id: "P003",
      name: "Product C",
      category: "Furniture",
      status: "Discontinued",
      sales: 200,
      stock: 50,
      incomingAddress: "Chicago",
      arrivalDate: "2025-03-10",
      destinationAddress: "Miami",
      departureDate: "2025-03-15",
    },
  ];

  const filteredProducts = productRecords.filter((record) =>
    record.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (statusFilter === "all" || record.status === statusFilter)
  );

  const statusBadge = (status) => {
    const colorMap = { Available: "green", "Out of Stock": "red", Discontinued: "orange" };
    return <Badge color={colorMap[status]} text={status} />;
  };

  const columns = [
    { title: "Product ID", dataIndex: "id", key: "id" },
    { title: "Product Name", dataIndex: "name", key: "name" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Status", dataIndex: "status", key: "status", render: statusBadge },
    { title: "Sales", dataIndex: "sales", key: "sales" },
    { title: "Stock", dataIndex: "stock", key: "stock" },
    { title: "Incoming Address", dataIndex: "incomingAddress", key: "incomingAddress" },
    { title: "Arrival Date", dataIndex: "arrivalDate", key: "arrivalDate" },
    { title: "Destination Address", dataIndex: "destinationAddress", key: "destinationAddress" },
    { title: "Departure Date", dataIndex: "departureDate", key: "departureDate" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button icon={<EyeOutlined />} onClick={() => openDrawer(record)}>View</Button>
      ),
    },
  ];

  const openDrawer = (product) => {
    setSelectedProduct(product);
    setIsDrawerVisible(true);
  };

  const handleCreate = (values) => {
    console.log("New Product Data:", values);
    setIsModalVisible(false);
  };

  return (
    <div className="product-tracking-container">
      <Card className="product-tracking-card" title="Product Tracking">
       {/* Filter & Search Section */}
      <Space className="filter-section" size="middle" style={{ marginBottom: 20 }}>
        <Input
          placeholder="Search product..."
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 250 }}
        />
        <Select defaultValue="all" onChange={setStatusFilter} style={{ width: 180 }}>
          <Option value="all">All Products</Option>
          <Option value="Available">Available</Option>
          <Option value="Out of Stock">Out of Stock</Option>
          <Option value="Discontinued">Discontinued</Option>
        </Select>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>Add Product</Button>

      </Space>
        {/* Product Table */}
        <Table 
  dataSource={filteredProducts} 
  columns={columns} 
  rowKey="id" 
  bordered 
  pagination={{ pageSize: 5 }}
/>


        {/* Product Details Drawer */}
        <Drawer 
          title="Product Details" 
          placement="right" 
          onClose={() => setIsDrawerVisible(false)} 
          open={isDrawerVisible}
        >
          {selectedProduct && (
            <>
              <p><strong>Product ID:</strong> {selectedProduct.id}</p>
              <p><strong>Name:</strong> {selectedProduct.name}</p>
              <p><strong>Category:</strong> {selectedProduct.category}</p>
              <p><strong>Status:</strong> {statusBadge(selectedProduct.status)}</p>
              <p><strong>Sales:</strong> {selectedProduct.sales}</p>
              <p><strong>Stock:</strong> {selectedProduct.stock}</p>
              <p><strong>Incoming Address:</strong> {selectedProduct.incomingAddress}</p>
              <p><strong>Arrival Date:</strong> {selectedProduct.arrivalDate}</p>
              <p><strong>Destination Address:</strong> {selectedProduct.destinationAddress}</p>
              <p><strong>Departure Date:</strong> {selectedProduct.departureDate}</p>
              <Button 
                type="link" 
                icon={<ArrowRightOutlined />} 
                onClick={() => navigate(`/product/${selectedProduct.id}`)}
              >
                More Details
              </Button>
            </>
          )}
        </Drawer>

        {/* Add Product Form */}
        <AddProductForm
          visible={isModalVisible}
          onCreate={handleCreate}
          onCancel={() => setIsModalVisible(false)}
        />
      </Card>
    </div>
  );
};

export default StoreTracking;
