import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import './ActionDropdown.css';

const ActionDropdown = ({ onEdit, onDelete, onView, record }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Toggle dropdown visibility
    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev);
    };

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!record) {
        return null;
    }

    return (
        <div className={`action-dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
                <i className="fas fa-ellipsis-v"></i>
            </button>
            {isOpen && (
                <ul className="dropdown-menu">
                    <li>
                        <button onClick={() => { onEdit(record); setIsOpen(false); }}>
                            <i className="fas fa-edit"></i> Edit
                        </button>
                    </li>
                    <li>
                        <button onClick={() => { onDelete(record); setIsOpen(false); }}>
                            <i className="fas fa-trash"></i> Delete
                        </button>
                    </li>
                    <li>
                        <button onClick={() => { onView(record); setIsOpen(false); }}>
                            <i className="fas fa-eye"></i> View
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

ActionDropdown.propTypes = {
    record: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
};

export default ActionDropdown;
