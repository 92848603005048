import React, { useState, useEffect } from "react";
import { Table, Card, Space, Input, Select, Badge, Drawer, Button, Modal, Dropdown, Menu, Form, message, DatePicker } from "antd";
import { SearchOutlined, EyeOutlined, MoreOutlined, PlusOutlined, RobotOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment"; 
import "./ComplaintTracking.css";
import ComplaintDetailsViewer from './components/ComplaintDetailsViewer';
import { Checkbox } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const api_url = process.env.REACT_APP_API_URL;

const ComplaintTracking = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isAIAssistModalVisible, setIsAIAssistModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [aiQuery, setAIQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [warrantyFilter, setWarrantyFilter] = useState("all");
  const [amcType, setAmcType] = useState("");
  const [complaintRecords, setComplaintRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // Fetch all complaints
  const fetchComplaints = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api_url}/complaints`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        const mappedData = data.map(item => ({
          id: item.id,
          staff: item.staff_name,
          siteName: item.site_name,
          warranty: item.warranty_status,
          complaintNo: item.complaint_no,
          date: item.complaint_date,
          nature: item.nature_of_complaint,
          description: item.description,
        }));
        setComplaintRecords(mappedData);
     
      } else {
        const errorData = await response.json();
        message.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error fetching complaints:', error);
      message.error('Failed to fetch complaints');
    } finally {
      setLoading(false);
    }
  };
  const handleAddComplaint = async () => {
    form.resetFields();
    setIsAddModalVisible(true);
  };

  const handleAddComplaintSubmit = async () => {
    try {
      const values = await form.validateFields();
      const complaintData = {
        staff_name: values.staff,
        site_name: values.siteName,
        warranty_status: values.warranty === "Yes" ? "Yes" : `No (${amcType})`,
        complaint_no: values.complaintNo,
        description: values.description,
        complaint_date: values.complaintDate.format("YYYY-MM-DD"), 
        nature_of_complaint: values.nature_of_complaint, 
      };
  
      console.log("Payload being sent:", complaintData); 
  
      const response = await fetch(`${api_url}/complaints`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(complaintData),
      });
  
      if (response.ok) {
        const data = await response.json();
        setComplaintRecords([...complaintRecords, data]);
        form.resetFields();
        setAmcType("");
        setIsAddModalVisible(false);
        message.success("Complaint added successfully!");
        fetchComplaints(); 
      } else {
        const errorData = await response.json();
        message.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error adding complaint:', error);
      message.error('Failed to add complaint');
    }
  };
  
  // Update complaint
  const handleUpdateComplaint = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        staff_name: values.staff,
        site_name: values.siteName,
        warranty_status: values.warranty,
        complaint_no: values.complaintNo,
        complaint_date: values.complaintDate,
        nature_of_complaint: values.nature_of_complaint,
        description: values.description
      };
      const response = await fetch(`${api_url}/complaints/${values.complaintNo}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (response.ok) {
        message.success('Complaint updated successfully');
        fetchComplaints();
        setIsEditModalVisible(false);
      }
    } catch (error) {
      console.error('Error updating complaint:', error);
    }
  };

  const handleEdit = async (record) => {
    setLoading(true);
    try {
      const complaintData = await fetchComplaintDetails(record.complaintNo);
      form.setFieldsValue({
        staff: complaintData.staff_name,
        siteName: complaintData.site_name,
        warranty: complaintData.warranty_status,
        complaintNo: record.complaintNo,
        complaintDate: moment(complaintData.complaint_date), 
        nature_of_complaint: complaintData.nature_of_complaint,
        description: complaintData.description
      });
      setIsEditModalVisible(true);
    } catch (error) {
      console.error('Error preparing edit:', error);
    } finally {
      setLoading(false);
    }
  };

  // Delete complaint
  const handleDeleteComplaint = async (complaint_no) => {
    try {
      await fetch(`${api_url}/complaints/${complaint_no}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setComplaintRecords(complaintRecords.filter(complaint => complaint.complaintNo !== complaint_no));
      message.success('Complaint deleted successfully');
    } catch (error) {
      console.error('Error deleting complaint:', error);
      message.error('Failed to delete complaint');
    }
  };

  const fetchComplaintDetails = async (id) => {
    try {
      const response = await fetch(`${api_url}/complaints/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        const mappedComplaint = {
          id: data.id,
          staff: data.staff_name,
          siteName: data.site_name,
          warranty: data.warranty_status,
          complaintNo: data.complaint_no,
          date: data.complaint_date,
          nature: data.nature_of_complaint,
          description: data.description,
        };
        console.log("Mapped Complaint Details:", mappedComplaint); 
        setSelectedComplaint(mappedComplaint);
        return data;
      } else {
        const errorData = await response.json();
        message.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error fetching complaint details:', error);
      message.error('Failed to fetch complaint details');
    }
  };
  

  useEffect(() => {
    fetchComplaints();
  }, []);

  const filteredComplaints = complaintRecords.filter(
    (record) =>
      (record.staff || "").toLowerCase().includes(searchTerm.toLowerCase()) &&
      (warrantyFilter === "all" || warrantyFilter === record.warranty)
  );
  

  const warrantyBadge = (warranty) => {
    const colorMap = {
      "Yes": "green",
      "No (AMC)": "blue",
      "No (No AMC)": "red",
    };
    return <Badge color={colorMap[warranty]} text={warranty} />;
  };

  const handleView = async (record) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${api_url}/complaints/${record.complaintNo}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const complaintData = await response.json();
      if (!complaintData) {
        throw new Error('No complaint data received');
      }
      console.log('Fetched Complaint Data:', complaintData);
      setSelectedComplaint(complaintData);
      setIsDrawerVisible(true);
      return complaintData;
    } catch (error) {
      console.error('Error fetching complaint:', error);
      message.error('Failed to load complaint details');
    }
  };

  const actionMenu = (record) => (
    <Menu className="action-menu">
      <Menu.Item 
        key="view" 
        icon={<EyeOutlined />} 
        className="action-menu-item view-item"
        onClick={() => handleView(record)}
      >
        View
      </Menu.Item>
      <Menu.Item 
        key="edit" 
        icon={<EditOutlined />} 
        className="action-menu-item edit-item"
        onClick={() => handleEdit(record)}
      >
        Edit
      </Menu.Item>
      <Menu.Item 
        key="delete" 
        icon={<DeleteOutlined />}
        className="action-menu-item delete-item"
        onClick={() => {
          Modal.confirm({
            title: 'Delete Complaint',
            content: 'Are you sure you want to delete this complaint?',
            okText: 'Yes',
            cancelText: 'No',
            okButtonProps: { danger: true },
            onOk: () => handleDeleteComplaint(record.complaintNo)
          });
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    { title: "Staff's Name", dataIndex: "staff", key: "staff" },
    { title: "Site Name", dataIndex: "siteName", key: "siteName" },
    { title: "Warranty", dataIndex: "warranty", key: "warranty", render: warrantyBadge },
    { title: "Complaint No.", dataIndex: "complaintNo", key: "complaintNo" },
    { 
      title: "Date", 
      dataIndex: "date", 
      key: "date", 
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleDateString('en-GB', { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      },
    },
    { title: "Nature of Complaint", dataIndex: "nature", key: "nature" },
    { 
      title: "Actions", 
      key: "actions",
      width: 80,
      align: 'center',
      render: (_, record) => (
        <Dropdown 
          overlay={actionMenu(record)} 
          trigger={["click"]}
          placement="bottomRight"
        >
          <Button 
            icon={<MoreOutlined />} 
            className="more-actions-button"
          />
        </Dropdown>
      ),
    },
  ];

  const resetAIAssistModal = () => {
    setAIQuery('');
    setSelectedFields([]);
    setIsAIAssistModalVisible(false);
  };

  const handleAIAssist = async () => {
    try {
      const formValues = form.getFieldsValue();
      const selectedData = {};
      
      const fieldMappings = {
        staff: 'staff',
        siteName: 'site_name',
        warranty: 'warranty_status',
        complaintNo: 'complaint_no',
        complaintDate: 'complaint_date',
        nature_of_complaint: 'nature_of_complaint',
        description: 'description'
      };

      selectedFields.forEach(field => {
        if (formValues[field]) {
          selectedData[fieldMappings[field]] = formValues[field];
        }
      });

      if (Object.keys(selectedData).length === 0) {
        message.warning('Please select at least one field with data');
        return;
      }

      message.loading({ content: 'Generating description...', key: 'generateDesc' });
      const response = await fetch(`${api_url}/api/generate-description`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          fields: selectedData,
          query: aiQuery
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate description');
      }

      const data = await response.json();
      if (data.success) {
        await form.setFields([
          {
            name: 'description',
            value: data.description,
            touched: true
          }
        ]);

        form.validateFields(['description']);

        resetAIAssistModal();
        message.success({ content: 'Description generated!', key: 'generateDesc' });
      } else {
        throw new Error(data.error || 'Failed to generate description');
      }
    } catch (error) {
      console.error('Error in handleAIAssist:', error);
      message.error({ content: 'Failed to generate description: ' + error.message, key: 'generateDesc' });
    }
  };

  return (
    <div className="complaint-tracking-container">
      <Card className="complaint-tracking-card" title="Complaint Tracking">
        <Space className="search-section" size="middle">
          <Space size="middle">
            <Input
              placeholder="Search staff..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <Select defaultValue="all" onChange={setWarrantyFilter} className="warranty-select">
              <Option value="all">All Complaints</Option>
              <Option value="Yes">With Warranty</Option>
              <Option value="No (AMC)">No (AMC)</Option>
              <Option value="No (No AMC)">No (No AMC)</Option>
            </Select>
          </Space>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddComplaint}>
            New Complaint
          </Button>
        </Space>
        
        <Table 
          loading={loading}
          dataSource={filteredComplaints} 
          columns={columns} 
          rowKey="id" 
          bordered 
          pagination={{ pageSize: 5 }} 
        />

        <Drawer
          title="Complaint Details"
          placement="right"
          onClose={() => setIsDrawerVisible(false)}
          open={isDrawerVisible}
          width={800}
        >
          {selectedComplaint && <ComplaintDetailsViewer complaint={selectedComplaint} />}
        </Drawer>

        <Modal
          title="New Complaint"
          open={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          className="complaint-modal"
          width={720}
          footer={
            <div className="modal-footer">
              <Button 
                onClick={() => setIsAddModalVisible(false)}
                type="text"
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button 
                type="primary" 
                onClick={handleAddComplaintSubmit}
                className="submit-button"
              >
                Add Complaint
              </Button>
            </div>
          }
        >
          <Form 
            layout="vertical" 
            form={form}
            className="complaint-form"
          >
            <Form.Item name="staff" label="Staff Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="siteName" label="Site Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="warranty" label="Warranty" rules={[{ required: true }]}>
              <Select onChange={(value) => setAmcType(value === "No" ? "AMC" : "")}>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            {amcType && (
              <Form.Item name="amcType" label="AMC Type" rules={[{ required: true }]}>
                <Select onChange={(value) => setAmcType(value)}>
                  <Option value="AMC">AMC</Option>
                  <Option value="No AMC">No AMC</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item name="complaintNo" label="Complaint No." rules={[{ required: true, message: 'Please enter complaint number!' }]}>
              <Input placeholder="Enter complaint number" />
            </Form.Item>
            <Form.Item
              name="nature_of_complaint"
              label="Nature of Complaint"
              rules={[{ required: true, message: "Please select the nature of the complaint!" }]}
            >
              <Select placeholder="Select Nature of Complaint">
                <Option value="Meter Issues">Meter Issues</Option>
                <Option value="Display Issues">Display Issues</Option>
                <Option value="Modem Issues">Modem Issues</Option>
                <Option value="Electrical Issues">Electrical Issues</Option>
                <Option value="Sensor Issues">Sensor Issues</Option>
                <Option value="Interface & Connectivity">Interface & Connectivity</Option>
                <Option value="Structural Work">Structural Work</Option>
                <Option value="General Complaints">General Complaints</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item 
              name="complaintDate" 
              label="Complaint Date" 
              rules={[{ required: true, message: 'Please select complaint date' }]}
            >
              <DatePicker 
                className="date-picker"
                format="DD-MM-YYYY"
                placeholder="Select date"
                inputReadOnly
              />
            </Form.Item>
            <Form.Item 
              name="description" 
              label="Description" 
              rules={[{ required: false }]}
              className="description-container"
            >
              <div>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.description !== currentValues.description}
                >
                  {({ getFieldValue }) => (
                    <TextArea 
                      value={getFieldValue('description')}
                      rows={4} 
                      placeholder="Enter complaint details or use AI Assist to help you write..." 
                      className="description-textarea"
                      onChange={(e) => form.setFieldsValue({ description: e.target.value })}
                    />
                  )}
                </Form.Item>
                <div className="ai-assist-button-container">
                  <Button
                    type="link"
                    size="small"
                    onClick={() => setIsAIAssistModalVisible(true)}
                    className="ai-assist-button"
                    icon={<RobotOutlined />}
                  >
                    Write with AI
                  </Button>
                </div>
              </div>
            </Form.Item>            
          </Form>
        </Modal>

        <Modal
          title="Edit Complaint"
          open={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          className="complaint-modal"
          width={720}
          footer={[
            <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleUpdateComplaint} className="submit-button">
              Update Complaint
            </Button>
          ]}
        >
          <Form 
            layout="vertical" 
            form={form}
            className="complaint-form"
          >
            <Form.Item name="staff" label="Staff Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="siteName" label="Site Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="warranty" label="Warranty" rules={[{ required: true }]}>
              <Select onChange={(value) => setAmcType(value === "No" ? "AMC" : "")}>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
            {amcType && (
              <Form.Item name="amcType" label="AMC Type" rules={[{ required: true }]}>
                <Select onChange={(value) => setAmcType(value)}>
                  <Option value="AMC">AMC</Option>
                  <Option value="No AMC">No AMC</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item name="complaintNo" label="Complaint No." rules={[{ required: true, message: 'Please enter complaint number!' }]}>
              <Input placeholder="Enter complaint number" />
            </Form.Item>
            <Form.Item
              name="nature_of_complaint"
              label="Nature of Complaint"
              rules={[{ required: true, message: "Please select the nature of the complaint!" }]}
            >
              <Select placeholder="Select Nature of Complaint">
                <Option value="Meter Issues">Meter Issues</Option>
                <Option value="Display Issues">Display Issues</Option>
                <Option value="Modem Issues">Modem Issues</Option>
                <Option value="Electrical Issues">Electrical Issues</Option>
                <Option value="Sensor Issues">Sensor Issues</Option>
                <Option value="Interface & Connectivity">Interface & Connectivity</Option>
                <Option value="Structural Work">Structural Work</Option>
                <Option value="General Complaints">General Complaints</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item 
              name="complaintDate" 
              label="Complaint Date" 
              rules={[{ required: true, message: 'Please select complaint date' }]}
            >
              <DatePicker 
                className="date-picker"
                format="DD-MM-YYYY"
                placeholder="Select date"
                inputReadOnly
              />
            </Form.Item>
            <Form.Item 
              name="description" 
              label="Description" 
              rules={[{ required: false }]}
              className="description-container"
            >
              <div>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.description !== currentValues.description}
                >
                  {({ getFieldValue }) => (
                    <TextArea 
                      value={getFieldValue('description')}
                      rows={4} 
                      placeholder="Enter complaint details or use AI Assist to help you write..." 
                      className="description-textarea"
                      onChange={(e) => form.setFieldsValue({ description: e.target.value })}
                    />
                  )}
                </Form.Item>
                <div className="ai-assist-button-container">
                  <Button
                    type="link"
                    size="small"
                    onClick={() => setIsAIAssistModalVisible(true)}
                    className="ai-assist-button"
                    icon={<RobotOutlined />}
                  >
                    Write with AI
                  </Button>
                </div>
              </div>
            </Form.Item>            
          </Form>
        </Modal>

        <Modal
          title="AI Assistance"
          open={isAIAssistModalVisible}
          onCancel={resetAIAssistModal}
          className="ai-modal"
          footer={
            <div className="ai-modal-footer">
              <Button 
                onClick={resetAIAssistModal}
                className="ai-modal-button"
              >
                Cancel
              </Button>
              <Button 
                type="primary" 
                onClick={handleAIAssist}
                className="ai-modal-button"
              >
                Write Description
              </Button>
            </div>
          }
        >
          <div className="ai-modal-content">
            <div>
              <div className="ai-query-section">Your Query</div>
              <TextArea
                value={aiQuery}
                onChange={(e) => setAIQuery(e.target.value)}
                placeholder="What would you like the AI to help you with? (e.g., 'Generate a technical description for this meter issue')"
                rows={3}
                className="ai-query-textarea"
              />
            </div>
            <div>
              <div className="ai-fields-section">Consider these fields:</div>
              <Checkbox.Group
                value={selectedFields}
                onChange={setSelectedFields}
                className="ai-checkbox-group"
              >
                <Checkbox value="staff">Staff Name</Checkbox>
                <Checkbox value="siteName">Site Name</Checkbox>
                <Checkbox value="warranty">Warranty Status</Checkbox>
                <Checkbox value="complaintNo">Complaint Number</Checkbox>
                <Checkbox value="date">Complaint Date</Checkbox>
                <Checkbox value="nature">Nature of Complaint</Checkbox>
              </Checkbox.Group>
            </div>
          </div>
        </Modal>
      </Card>
    </div>
  );
};

export default ComplaintTracking;