import React, { useState, useCallback, useEffect, useRef } from "react";
import { Bell, Store, User, ChevronDown, LogOut, Moon, Sun, Headset } from "lucide-react"; 
import './Header.css';
const api_url = process.env.REACT_APP_API_URL;

const Header = ({ activeTab, onTabChange, onLogout, isDarkMode, setIsDarkMode }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null); 
  const profileRef = useRef(null);
  const notificationsRef = useRef(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${api_url}/users`, {
          method: 'GET',
          credentials: 'include',  
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched User Data:", data);  // Debug log

          setUser(data); 
        } else {
          const errorData = await response.json();
          console.error(`Error fetching user: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const toggleDarkMode = useCallback(() => {
    if (setIsDarkMode && typeof setIsDarkMode === "function") {
      setIsDarkMode((prev) => !prev);
    }
  }, [setIsDarkMode]);

  const handleProfileToggle = (event) => {
    event.stopPropagation();
    setIsProfileOpen((prev) => !prev);
    setIsNotificationsOpen(false);
  };

  const handleNotificationsToggle = (event) => {
    event.stopPropagation();
    setIsNotificationsOpen((prev) => !prev);
    setIsProfileOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prev) => !prev);
    setIsProfileOpen(false);
    setIsNotificationsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current && 
        !profileRef.current.contains(event.target) &&
        notificationsRef.current && 
        !notificationsRef.current.contains(event.target)
      ) {
        setIsProfileOpen(false);
        setIsNotificationsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className="header">
      <div className="header-wrapper">
        <div className="title-container">
          <h1 className="header-title">Tracking System</h1>
        </div>

        <nav className="header-nav">
  <button
    onClick={() => onTabChange("meter")}
    className={`tab-button ${activeTab === "meter" ? "active" : ""}`}
  >
    <Bell size={18} />
    <span>Meter Tracking</span>
  </button>
  <button
    onClick={() => onTabChange("store")}
    className={`tab-button ${activeTab === "store" ? "active" : ""}`}
  >
    <Store size={18} /> {/* Changed to Store icon */}
    <span>Store Tracking</span>
  </button>
  <button
  onClick={() => onTabChange("complaint")}
  className={`tab-button ${activeTab === "complaint" ? "active" : ""}`}
>
  <Headset size={18} />
  <span>Complaint Tracking</span>
</button>

</nav>

        <div className="header-actions">
          <button className="icon-button" onClick={toggleDarkMode}>
            {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
          </button>

          {/* Notifications Dropdown */}
          <div className="dropdown-container" ref={notificationsRef}>
            <button 
              className={`icon-button ${isNotificationsOpen ? 'active' : ''}`} 
              onClick={handleNotificationsToggle}
            >
              <Bell size={20} />
              <span className="notification-badge"></span>
            </button>
            {isNotificationsOpen && (
              <div className="dropdown-menu notifications-menu">
                <h3>Notifications</h3>
                <div className="notification-item">
                  <p>New meter added</p>
                  <span>5 minutes ago</span>
                </div>
                <div className="notification-item">
                  <p>Import completed</p>
                  <span>1 hour ago</span>
                </div>
                <button className="view-all" onClick={() => {/* handle click */}}>
              View all notifications
              </button>
              </div>
            )}
          </div>

          {/* Profile Dropdown */}
          <div className="dropdown-container" ref={profileRef}>
<button className={`profile-button ${isProfileOpen ? 'active' : ''}`} onClick={handleProfileToggle}>
  <User size={20} />
  <span>
  {user ? user.name : 'Loading...'}
</span>
  <ChevronDown size={16} className={isProfileOpen ? 'rotate-180' : ''} />
</button>

            {isProfileOpen && (
              <div className="dropdown-menu profile-menu">
                <button onClick={onLogout} className="logout-button">
                  <LogOut size={18} />
                  <span>Logout</span>
                </button>
              </div>
            )}
          </div>

          <button 
            className="mobile-menu-toggle"
            onClick={handleMobileMenuToggle}
          >
            <div className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
      </div>

      {isMobileMenuOpen && (
        <nav className="mobile-nav">
          <button 
            onClick={() => onTabChange("meter")}
            className={activeTab === "meter" ? "active" : ""}
          >
            Meter Tracking
          </button>
          <button 
            onClick={() => onTabChange("store")}
            className={activeTab === "store" ? "active" : ""}
          >
            Store Tracking
          </button>
        </nav>
      )}
    </header>
  );
};

export default Header;